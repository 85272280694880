import { InfoComponent } from "./InfoComponent";

export const Regiao = () => {
  const info = [
    {
      Titulo: "Historia antes de 1500 - 1616",
      image:
        "https://res.cloudinary.com/dtfppvicy/image/upload/v1684727274/DSC_0760_zdilva.jpg",
      texto:
        "<b> antes de 1500 </b> Os tupinambas moraban na nossa regiao e a chamaban de heway <br> <b> 1503 </b> Americo repulscio chegou na praia de rama <br> <b> 1575  </b> Acontece a guerra onde os portugueses expulsarao os indios tumpinambas, Franceses e holandeses que estaban na regiao explotando o <b> pau brasil </b>",
    },
    // {
    //   Titulo: "Historia",
    //   image:
    //     "https://res.cloudinary.com/dtfppvicy/image/upload/v1684727274/DSC_0760_zdilva.jpg",
    //   texto:
    //     "Os indios <b> tupinanbas e sambaguis </b> se encontrabam na regiao quando os portugueses chegarao em <b> 1503 </b> na praia da rama em arraial, onde so visando o lucro da retirada do pau brasil deixarao 24 soldados para fazer una fatoria,so em <b> 1615 </b> e oficialisada a ciudade <b> santa helena de cabo frio </b> onde a industria de sal foi a pionera no <b> XIIX </b> , sendo bairros de <b> cabo frio </b>, <b> Arraial do cabo se enmancipa en 1984 e buzios em 1996 </b> se tornando ciudades turisticas. Hoje o ABC da costa do sol tem muitas mais historias e belezas para compartir",
    // },
    {
      Titulo: "Curiosidades",
      image:
        "https://res.cloudinary.com/dtfppvicy/image/upload/v1685300884/DSC_0539_zjclag.jpg",
      texto:
        "Os <b> sambaguis </b> se dedicaban a caça de <b> moluscos,Pesca e Ceramica.</b> Afiban suas farramentas na pedra ,O<b> pau brasil</b> predominaba em toda nossa regiao no <b> siglo XIX </b> <br > o ciclo de sal foi administrado pelas familias mais adineradas colocando escravos a fazer o trabalho pesado nas salinas.<br> Aqui surgio a primeira reveion contra a coroa,<br> Arraial tenia um tren das salinas atre o porto",
    },
    {
      Titulo: "Geografia da regiao ",
      image:
        "https://res.cloudinary.com/dtfppvicy/image/upload/v1685300891/DSC_0480_dg2ydz.jpg",
      texto:
        "<b> Area 2.004.003 km² </b> <br>  Populacao 538.650 habitantes dividos em los municipios <b> Araruama,Armacao dos Buzios,Arraial do cabo,Cabo frio, Iguaba grande, San pedro da aldeia e Saquarema </b> <br> A regiao do litoral fluminense foi marcada pelo turismo e a industria do sal",
    },
  ];

  return <InfoComponent Info={info} />;
};

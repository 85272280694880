import React from "react";
import { Link } from "react-router-dom";
import img from "../Images/eSsencia.png";
import "../styles/NavBar.css";

export const NavBar = () => {
  return (
    <nav className="Nav">
      <ul className="UlNav">
        <li className="LiNav">
          <Link to="/" style={{ fontSize: "20px" }} className="ANav">
            <div className="DivLogo">
              {" "}
              <img alt="Essencia Lagos" src={img} />
              <h2 className="h2Logo">Essencia Lagos</h2>
            </div>
          </Link>
        </li>
        <li className="LiNav">
          <Link to="/Passeios" className="ANav">
            Passeios
          </Link>
        </li>
        <li className="LiNav">
          <Link to="/Paquetes" className="ANav">
            Paquetes
          </Link>
        </li>
      </ul>
    </nav>
  );
};

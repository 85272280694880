import React, { useEffect, useState } from "react";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { PaquetesInfo } from "../Passeios";
import { useParams } from "react-router-dom";
import { Error } from "./Error";
import parse from "html-react-parser";
import "../styles/InfoPacote.css";

export const InfoPaquetes = () => {
  const { Pacote } = useParams();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  const filtrado = PaquetesInfo.find((e) => e.Title === Pacote);
  if (!filtrado) return <Error />;

  if (loading) {
    return (
      <div id="loader">
        <AiOutlineLoading3Quarters className="rotate" size={100} />
      </div>
    );
  }

  return (
    <div className="PacoteDiv">
      <h2 className="PacoteH2">{filtrado.Title}</h2>
      <p className="PacoteDesc">{filtrado.Descripcion}</p>
      <p className="PacoteInfo">{parse(filtrado.Info)}</p>
    </div>
  );
};

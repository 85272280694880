import React, { useState, useEffect } from "react";
import "../styles/Home.css";
import { Pasarela } from "./Pasarela";
import Essencia from "../Images/Essencia.JPG";
import Regiao from "../Images/Regiao.JPG";
import { Link } from "react-router-dom";
import { AiOutlineLoading3Quarters, AiOutlineArrowsAlt } from "react-icons/ai";

export const Home = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  if (loading) {
    return (
      <div id="loader">
        <AiOutlineLoading3Quarters className="rotate" size={100} />
      </div>
    );
  }

  return (
    <div className="HomeContainer">
      <Pasarela />
      {/* <div className="Text-Box-Nossavida">
        <div>
          <h2 className="NossaVida">
            Na nossa vida so precisamos de um simples
          </h2>
        </div>
        <div className="Regiao">
          <div className="Ciudades">
            <h2>A</h2>
            <p>rrial</p>
          </div>
          <div className="Ciudades">
            <h2>B</h2>
            <p>uzios</p>
          </div>
          <div className="Ciudades">
            <h2>C</h2>
            <p>abo frio</p>
          </div>
        </div>
      </div> */}
      <div className="Text-Box-Nossahistoria">
        <h2>Nossa historia</h2>
        <div className="Box-nos">
          <div
            className="Box"
            style={{
              backgroundImage: `url(${Essencia})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
            }}
          >
            <Link to="/Essencia" className="Link-Box_Double">
              <h3>
                Conhece a essencia <AiOutlineArrowsAlt id="OutlineArrow" />
              </h3>
            </Link>
          </div>
          <div
            className="Box"
            style={{
              backgroundImage: `url(${Regiao})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
            }}
          >
            <Link to="/Regiao" className="Link-Box_Double">
              <h3>
                Conhece a regiao <AiOutlineArrowsAlt id="OutlineArrow" />
              </h3>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

import React, { useEffect, useState } from "react";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { useParams } from "react-router-dom";
import { DetailPaseos } from "../Passeios";
import "../styles/InfoPasseio.css";
import { Error } from "./Error";

export const InfoPasseio = () => {
  const { Passeio } = useParams();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  if (loading) {
    return (
      <div id="loader">
        <AiOutlineLoading3Quarters className="rotate" size={100} />
      </div>
    );
  }

  const filtrado = DetailPaseos.find((e) => e.Passeio === Passeio);
  if (!filtrado) return <Error />;

  const { Roteiro } = filtrado;

  const RowRenderer = ({ Info, key }) => {
    return (
      <div className="RoteiroMap" key={key}>
        <p style={{ margin: 0, fontSize: "1.5rem" }}>{Info}</p>
      </div>
    );
  };

  return (
    <div className="DetailDiv">
      <h1 className="DetailTitle">{filtrado.Passeio} </h1>
      <div className="InfoPri">
        <div className="DivValor">
          <h3 className="Titles">Valor</h3>
          <div className="EdadesDiv">
            {filtrado.Valor.map((e) => (
              <p style={{ margin: 0, fontSize: "1.2rem" }} className="Valor">
                {e}
              </p>
            ))}
          </div>
        </div>
        <div>
          <h3 className="Titles">Embarque</h3>
          <div className="EdadesDiv">
            <p className="Embarque">{filtrado.Embarque} </p>
          </div>
        </div>
        <div>
          <h3 className="Titles">Tempo</h3>
          <div className="EdadesDiv">
            <p style={{ margin: 0, fontSize: "1.2rem" }} className="Tempo">
              {filtrado.Tempo}{" "}
            </p>
          </div>
        </div>
      </div>
      <div>
        <h3 className="Titles">Roteiro </h3>
        <div
          style={{
            height: "250px",
            overflowY: "scroll",
            margin: "auto",
            padding: "10px",
          }}
          className="Roteiro"
        >
          {Roteiro.map((Rote, Index) => (
            <RowRenderer Info={Rote} Key={Index} />
          ))}
        </div>
      </div>
      <div>
        <h3 className="Titles">Obs</h3>
        <div className="EdadesDiv">
          <p style={{ margin: 0, fontSize: "1.2rem" }} className="Possui">
            {filtrado.Obs}{" "}
          </p>
        </div>
      </div>
    </div>
  );
};

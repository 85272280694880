import React, { useEffect, useState } from "react";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import "../styles/Passeios.css";
import { listaPasseios } from "../Passeios";
import { Link } from "react-router-dom";

export const Passeios = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  if (loading) {
    return (
      <div id="loader">
        <AiOutlineLoading3Quarters className="rotate" size={100} />
      </div>
    );
  }

  return (
    <div>
      <div className="PaseoComponente">
        {listaPasseios.map((paseos) => (
          <div className="CiudadesDiv">
            <h3> {paseos.ciudad} </h3>
            <div className="AllPasseos">
              {paseos.paseos.map((ind) => (
                <Link to={ind.Passeio} className="LinkPasseio">
                  <div
                    className="PaseoDiv"
                    style={{ backgroundImage: `url(${ind.image})` }}
                  >
                    <h4 className="H4Component"> {ind.Passeio} </h4>
                  </div>
                </Link>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

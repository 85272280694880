import "../styles/Essencia.css";
import { InfoComponent } from "./InfoComponent";

export const Essencia = () => {
  const info = [
    {
      Titulo: "A historia da enssencia",
      image:
        "https://res.cloudinary.com/dtfppvicy/image/upload/v1685300994/DSC_0390_ofawos.jpg",
      texto:
        "Meu nome e <b>Cristina Kronstiner </b> cheguei no <b> Brasil </b> no ano <b> 2011 </b> con o sonho de conhecer mais o mundo e viajar, mais fiquei apaxionada pelo <b> rio de janeiro </b>, Comencei a trabalhar em turismo receptivo na costa verde com navios <b> MSC </b> e <b> COSTA </b> recebendo turistas do mundo enteiro, Tambem trabalhei em pousadas familinhares auxiliando na administracao e organicao. ate no ano <b> 2017 </b> me formei oficialmente como guia de turismo e decidi abrir minha propia empresa. Desde entao me espesialisei em turismo receptivo na <b> regiao dos lagos </b>",
    },
    {
      Titulo: "Objetivos",
      image:
        "https://res.cloudinary.com/dtfppvicy/image/upload/v1685301002/DSC_0258_x1l3wa.jpg",
      texto:
        "Nossa empresa nace do desejo de poder expor a essencia de toda a <b> regiao dos lagos </b> de una forma segura, comfortavel e honesta. Valoramos teu tempo e esforzo por isso queremos te brindar o melhor a preco justo, Focados na puntualidad e empenho",
    },
  ];
  return <InfoComponent Info={info} />;
};

import Barco from "./Images/Barco.png";
import Trilha from "./Images/Trilha.png";
import Buggy from "./Images/Buggy.png";
import Cuatri from "./Images/Cuatri.png";

export const DetailPaseos = [
  {
    Passeio: "Barco Ilha do Farol",
    image: Barco,
    Valor: [
      "Adulto(maior de 10 anos)",
      "Meia(crianca de 5 a 10 anos e adultos maior de 65 anos)",
      "Free(crianca até 5 anos)",
    ],
    Embarque: "Cais numero 3, Praia dos Anjos",
    Tempo: "Sao 3:30 horas de passeio.",
    Roteiro: [
      "Prainhas do pontal de atalaia (Bote ate areia) ",
      "Ilha do farol (Bote ate areia) ",
      "Enseada praia do forno (Parada para mergulho) ",
      "Perfil do gorila",
      "Fenda nossa senhora de assunção",
      "Gruta azul",
      "Buraco de meteorito",
      "Bogueirão",
      "Baxinhos",
    ],
    Obs: "Taxa da marinha 10 REAIS(obligatirio). No barco tem bar a bordo, banheiros, Colete salva-vidas para todos os passageiros, Flutuadores recreativos e banheiro feminino e masculino.",
    Enlance: "Barco",
  },
  {
    Passeio: "Buggy Buzios",
    image: Buggy,
    Valor: [
      "Por pessoa",
      "Temos preco diferenciados para grupo acima de 10 pessoas",
    ],
    Embarque: "A combinar",
    Tempo: "1:30 horas de passeio",
    Roteiro: [
      "Praia da armação",
      "João Fernandes",
      "João Fernandinho",
      "Ilha branca",
      "Pontal do criminoso",
      "Mirante de João Fernandes",
      "Praia da foca",
      "Mirante do forno e foca",
      "A última ferradura deixou vocês lá. Marcamos um horário e vamos tirar fotos na Praia da Ferradura, podendo ficar até as 17 horas. Se quiser, podemos fazer uma pausa de uma hora para tirar mais fotos.",
    ],
    Obs: "4 Perssoas + 1 motorista",
    Enlance: "Barco",
  },
  {
    Passeio: "Passeio de Barco Buzios",
    image: Barco,
    Valor: [
      "Adulto(maior de 10 anos)",
      "Meia(crianca de 5 a 10 anos e adultos maior de 65 anos)",
      "Free(crianca até 5 anos)",
    ],
    Embarque: "A combinar",
    Tempo: "De 2 horas e meia até 3 horas de passeio",
    Roteiro: [
      "Canto",
      "Armação",
      "Ossos",
      "Azeda",
      "Azedinha",
      "João Fernandes",
      "João Fernandinho",
      "Tartaruga",
      "Amores",
      "Virgem",
      "Moças",
      " Manguinhos e 3 ilhas: Caboclo, Branca e Feia",
    ],
    Obs: "Colete salva-vidas para todos os passageiros, Flutuadores recreativos e banheiro feminino e masculino.",
    Enlance: "Barco",
  },
  {
    Passeio: "Barco Canal Itajuru",
    image: Barco,
    Valor: [
      "Adulto(maior de 10 anos)",
      "Meia(crianca de 5 a 10 anos e adultos maior de 65 anos)",
      "Free(crianca até 5 anos)",
    ],
    Embarque: "Terminal de Passeios de Barcos, Canal itajuru",
    Tempo: "2h e media horas de passeio",
    Roteiro: [
      "Canal Itajuru",
      "Passagem",
      "Condominio da Moringa",
      "Ilha do japones",
      "Boca da barra",
      "Ponta da carolina",
      "Forte de sao mateus",
      "Farol Lajinha",
      "Praia brava",
      "Ilha do papagaio (Parada para mergulho) ",
    ],
    Obs: "Colete salva-vidas para todos os passageiros, Bar a bordo, Flutuadores recreativos e banheiro feminino e masculino.",
    Enlance: "Barco",
  },
  {
    Passeio: "Trilha Ecologica",
    image: Trilha,
    Valor: [
      "Por pessoa",
      "Temos preco diferenciados para grupo acima de 10 pessoas",
    ],
    Embarque: "Terminal de transatlantico Cabo frio (Barrio da passagem)",
    Tempo:
      "Sao 3 horas de passeio, Com 3 paradas para descanso de 30 mins aprox",
    Roteiro: [
      "Travessia para Ilha do japones de taxi boat (Ida e volta)",
      "Entrada ao parque costa do sol",
      "Caverna dos escravos (Refugio do quilombo",
      "Ponta do chapeu",
      "Praia fofa",
      "Praia brava",
      "Retorno para Ilha do japones com parada para mergulho",
    ],
    Enlance: "Trilha",
  },
  {
    Passeio: "City tour Cabo frio",
    image: Trilha,
    Valor: [
      "Por pessoa",
      "Temos preco diferenciados para grupo acima de 10 pessoas",
    ],
    Embarque: "Terminal de transatlantico Cabo frio (Barrio da passagem)",
    Tempo: "Sao 3 horas de passeio",
    Roteiro: [
      "Morro do guia (Parada) ",
      "Rua das bikinis (Parada) ",
      "Ponte Feliciano sodre",
      "Charitas",
      "Camara Cabo frio",
      "Terminal maritimo",
      "Canal itajuro",
      "Passagem (Parada) ",
      "Igreja sao benedito",
      "Forte de Sao mateus (Parada) ",
      "Mirante do arpuador (Parada) ",
      "ponta da carolina",
      "Praia do forte",
    ],
    Obs: "Transporte com carro Padrao/Arcondicionador, 4 Perssoas, Guia credenciado",
    Enlance: "Trilha",
  },
  {
    Passeio: "City tour Arraial do cabo",
    image: Trilha,
    Valor: [
      "Por pessoa",
      "Temos preco diferenciados para grupo acima de 10 pessoas",
    ],
    Embarque: "A combinar",
    Tempo: "Sao 1 horas de passeio",
    Roteiro: [
      "Igreja Nossa senhora dos remedios ",
      "Marco historico ",
      "Porto",
      "Museo oceanografico",
      "Esculturas (Hermes Barcellos, Flavia alesandra) ",
    ],
    Obs: "Grupo de ate 10 pessoas",
    Enlance: "Trilha",
  },
  {
    Passeio: "City tour Buzios",
    image: Trilha,
    Valor: [
      "Por pessoa",
      "Temos preco diferenciados para grupo acima de 10 pessoas",
    ],
    Embarque: "A combinar",
    Tempo: "Sao 1 horas de passeio",
    Roteiro: [
      "Porto ",
      "Rua das Pedras ",
      "orla brigitte bardot",
      "Escultura dos pescadores",
      "Porto veleiro",
      "Igreja santa ana",
      "Nucleo historico",
    ],
    Obs: "Grupo de ate 10 pessoas",
    Enlance: "Trilha",
  },
  {
    Passeio: "Buggy Arraial do cabo",
    image: Buggy,
    Valor: [
      "Por pessoa",
      "Temos preco diferenciados para grupo acima de 10 pessoas",
    ],
    Embarque: "A combinar, Idem praia dos anjos ou porto",
    Tempo: "Sao 2 horas de passeio",
    Roteiro: [
      "Prainha",
      "Praia do pontal",
      "Praia do foguete",
      "Logoa vermelha",
      "Praia grande",
    ],
    Obs: "Praia do pontal(nao e da escadarria), 4 Perssoas + 1 motorista",
    Enlance: "Trilha",
  },
  {
    Passeio: "Cuatriciclo Arraial do cabo",
    image: Cuatri,
    Valor: [
      "Por pessoa",
      "Temos preco diferenciados para grupo acima de 10 pessoas",
    ],
    Embarque: "A combinar, Idem praia dos anjos ou porto",
    Tempo: "Sao 2 horas de passeio",
    Roteiro: [
      "Morro de mirando (Pedreira) ",
      "Curva do pontal (Praia do Pontal) ",
      "Praia do foguete",
      "Logoa vermelha",
      "Praia grande",
    ],
    Obs: "Ter carteira de habilitação CNH, G , O cliente pilota, 2 Pessoas por cuatri",
    Enlance: "Cuatri",
  },
];
export const PaquetesInfo = [
  {
    Title: "Pacote outubro de 2023",
    Descripcion: "Pacote de esscurcao de cabo frio",
    Info: "<b>11</b> quartos em <b>hotel de luxo</b> -> <b>22</b> passageiros <br> Entrada no sabado saida no domingo <br> Autoricao da ciudade <br> acompanhamento de guia <br> estacionamiento de onibus",
    image: Trilha,
  },
  {
    Title: "Autorizacion Buzios",
    Descripcion: "Autorizacion buzios guia de como fazer",
    Info: "Para um onibus de turismo entrar na cidade ele precisa de uma autorização especial do secretario de turismo. <br> A agencia deve fazer contato com guia local pare solicitara deve ter reserva em hotel ou algum tipo de serviçio turistico <br> OBS: El guia local deve estar junto com o grupo na entrada da cidade <br> <br> City tour: Omnibus/ Microonibus/ Van R$170 <br> Estacionamiento con hospedaje: Omnibus/ Microonibus/ Van R$100 <br> <a target=_blank href=https://egov.buziosdigital.rj.gov.br/pdf/999_2013_estabelecer_criterios_para_entrada_circulacao_e_estacionamento_de_onibus_de_turismo.pdf > Decreto oficial prefeitura de buzios </a>",
    image:
      "https://res.cloudinary.com/dtfppvicy/image/upload/v1686772267/Buzios_antgon.png",
  },
  {
    Title: "Autorizacion Arraial",
    Descripcion: "Autorizacion Arrial guia de como fazer",
    Info: `Para um onibus de turismo entrar na cidade ele precisa de uma autorização especial do secretario de turismo. <br> A agencia deve fazer contato com guia local pare solicitara deve ter reserva em hotel ou algum tipo de serviçio turistico <br> OBS: El guia local deve estar junto com o grupo na entrada da cidade <br> <br> City tour: Omnibus/ Microonibus/ Van R$170 <br> Estacionamiento con hospedaje: Omnibus/ Microonibus/ Van R$100 <br> <br> obs: o onibus so pode parar no estacionamento para onibus de turismo no revesche de ahi segue em jardineiras que sao onibus abertos ate o centro da cidade na praia dos anjos essa jardineira tem um custo de R$15 reais ida e volta nao se pode entrar nem com bebidas nem comidas. <br> <br> <a target=_blank href=https://drive.google.com/drive/folders/1x_RGrz3UOVGJAOfcyWNTOR1Bo_hITSJt > Decreto oficial prefeitura de Arraial </a> `,
    image:
      "https://res.cloudinary.com/dtfppvicy/image/upload/v1686771621/WhatsApp_Image_2023-06-14_at_16.17.59_ymcffl.jpg",
  },
  {
    Title: "Autorizacion Cabo frio",
    Descripcion: "Autorizacion Arrial guia de como fazer",
    Info: "Para um onibus de turismo entrar na cidade ele precisa de uma autorização especial do secretario de turismo. <br> A agencia deve fazer contato com guia local pare solicitara deve ter reserva em hotel ou algum tipo de serviçio turistico <br> OBS: El guia local deve estar junto com o grupo na entrada da cidade <br> <br> City tour: Omnibus/ Microonibus/ Van R$170 <br> Estacionamiento con hospedaje: Omnibus/ Microonibus/ Van R$100 <br> <a target=_blank href=https://drive.google.com/drive/folders/1x_RGrz3UOVGJAOfcyWNTOR1Bo_hITSJt > Decreto oficial prefeitura de Cabo frio </a> <br> <img style=width:90%;display:block;margin-left:auto;margin-top:2rem;margin-right:auto; src=https://res.cloudinary.com/dtfppvicy/image/upload/v1686768177/WhatsApp_Image_2023-06-14_at_14.10.26_o8vcin.jpg />",
    image:
      "https://res.cloudinary.com/dtfppvicy/image/upload/v1686771622/WhatsApp_Image_2023-06-14_at_16.19.01_mwhl1w.jpg",
  },
];

export const listaPasseios = [
  {
    ciudad: "Cabofrio",
    URL: "CaboFrio/",
    paseos: [
      {
        Passeio: "City tour Cabo frio",
        image:
          "https://res.cloudinary.com/dtfppvicy/image/upload/v1686242343/cuadrito4_zdxwxj.png",
      },
      {
        Passeio: "Passeio Cabo frio",
        image:
          "https://res.cloudinary.com/dtfppvicy/image/upload/v1686789456/passeocabofrio_zobjga.png",
      },
      {
        Passeio: "Trilha Ecologica",
        image: Trilha,
      },
    ],
    Traslado: {
      Ciudad: "Rio de janeiro ate Cabo frio",
      Preço: "A partir de R$500",
    },
  },
  {
    ciudad: "Arraial",
    URL: "Arraial/",
    paseos: [
      {
        Passeio: "City tour Arraial do cabo",
        image:
          "https://res.cloudinary.com/dtfppvicy/image/upload/v1686242343/cuadrito1_jd6mio.png",
      },
      {
        Passeio: "Barco Ilha do Farol",
        image:
          "https://res.cloudinary.com/dtfppvicy/image/upload/v1686789456/paseoarraial_qhgjx3.png",
      },
      {
        Passeio: "Buggy Arraial do cabo",
        image: Buggy,
      },
      {
        Passeio: "Cuatriciclo Arraial do cabo",
        image:
          "https://res.cloudinary.com/dtfppvicy/image/upload/v1686242343/cuadrito2_tpixrh.png",
      },
    ],
  },
  {
    ciudad: "Buzios",
    URL: "Buzios/",
    paseos: [
      {
        Passeio: "City tour Buzios",
        image:
          "https://res.cloudinary.com/dtfppvicy/image/upload/v1686789456/paseobuzios_j28hpe.png",
      },
      {
        Passeio: "Buggy Buzios",
        image:
          "https://res.cloudinary.com/dtfppvicy/image/upload/v1686789456/buggiebuzios_i80z3f.png",
      },
      {
        Passeio: "Passeio de Barco Buzios",
        image:
          "https://res.cloudinary.com/dtfppvicy/image/upload/v1686242342/cuadrito3_kywgfo.png",
      },
    ],
  },
];

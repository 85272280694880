import React from "react";
import "./App.css";
import { Route, Routes } from "react-router-dom";
import { Home } from "./components/Home";
import { Passeios } from "./components/Passeios";
import { Paquetes } from "./components/Paquetes";
import { Footer } from "./components/Footer";
import { NavBar } from "./components/NavBar";
import { InfoPasseio } from "./components/InfoPasseio";
import { InfoPaquetes } from "./components/InfoPaquetes";
import { Essencia } from "./components/Essencia";
import { Regiao } from "./components/Regiao";

function App() {
  return (
    <div className="App">
      <NavBar />
      <Routes>
        <Route path="/" element={<Home />}></Route>
        <Route path="/Passeios" element={<Passeios />}></Route>
        <Route path="/Essencia" element={<Essencia />}></Route>
        <Route path="/Regiao" element={<Regiao />}></Route>
        <Route path="/Passeios/:Passeio" element={<InfoPasseio />}></Route>
        {/* {listaPasseios.map(ciudad=> (
          ciudad.paseos.map(Paseo=> 
            // console.log(`/Passeios/${Paseo.Enlance}`)
            <Route path={`/Passeio/${Paseo.Enlance}`} element={<InfoPasseio ciudad={ciudad.ciudad} InfoPasseio={Paseo}  />}></Route>
            )
        ))} */}
        <Route path="/Paquetes" element={<Paquetes />}></Route>

        <Route path={`/Paquetes/:Pacote`} element={<InfoPaquetes />}></Route>
      </Routes>
      <Footer />
    </div>
  );
}

export default App;

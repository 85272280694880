import React from "react";
import "../styles/Footer.css";
import img from "../Images/eSsencia.png";
import { Link } from "react-router-dom";
import {
  AiOutlineInstagram,
  AiOutlineWhatsApp,
  AiFillFacebook,
} from "react-icons/ai";

export const Footer = () => {
  return (
    <div className="FooterDiv">
      <div className="FirtsDiv">
        <div className="ImgDiv">
          <Link to="/">
            {" "}
            <img alt="Essencia Lagos" src={img} />{" "}
          </Link>
        </div>
        <div className="IconsFooter">
          <a
            href="https://wa.me/+5522997113344"
            rel="noreferrer"
            target="_blank"
          >
            <AiOutlineWhatsApp /> WhatsApp
          </a>
          <a
            href="https://www.instagram.com/turismo_lagos/"
            rel="noreferrer"
            target="_blank"
          >
            <AiOutlineInstagram /> Instragram
          </a>
          <a
            href="https://www.facebook.com/essencialagos"
            rel="noreferrer"
            target="_blank"
          >
            <AiFillFacebook /> Facebook
          </a>
        </div>
      </div>
      <div className="SecondDiv"></div>
    </div>
  );
};

import React, { useEffect, useState } from "react";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
// import Barco from "../Images/Barco.png";
// import Buggy from "../Images/Buggy.png";
// import Trilha from "../Images/Trilha.png";
// import Cuatri from "../Images/Cuatri.png";
import { Link } from "react-router-dom";
import { PaquetesInfo } from "../Passeios";

export const Paquetes = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  if (loading) {
    return (
      <div id="loader">
        <AiOutlineLoading3Quarters className="rotate" size={100} />
      </div>
    );
  }

  return (
    <div>
      <div className="PaseoComponente">
        <div className="AllPasseos">
          {PaquetesInfo.map((ind) => (
            <Link to={ind.Title} className="LinkPasseio">
              <div
                className="PaseoDivPaquete"
                style={{ backgroundImage: `url(${ind.image})` }}
              >
                <h4 className="H4Component"> {ind.Title} </h4>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

export const Error = () => {
  return (
    <div
      style={{
        background: "#ffffffa9",
        padding: "20px",
        width: "700px",
        margin: "250px auto",
        borderRadius: "20px",
      }}
    >
      <h3>
        Desculpe, ocorreu um erro inesperado. Por favor, tente novamente mais
        tarde.
      </h3>
      <h3>
        Contate-nos para qualquer dúvida. Estamos prontos para ajudar. Whatsapp,
        Instragram ou Facebook disponíveis.
      </h3>
    </div>
  );
};

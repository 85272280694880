import React, { useEffect, useState } from "react";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import parse from "html-react-parser";
import "../styles/InfoComponent.css";

export const InfoComponent = ({ Info }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  if (loading) {
    return (
      <div id="loader">
        <AiOutlineLoading3Quarters className="rotate" size={100} />
      </div>
    );
  }

  return (
    <div className="Container-Essencia">
      {Info.map((Box, index) => (
        <div
          style={{
            flexDirection: index % 2 === 0 ? "row-reverse" : "row",
            color: "black",
            backgroundColor: "#57B9FF",
            gap: "1rem",
          }}
          className="Main-Essencia"
        >
          <div
            style={{
              backgroundImage: `url(${Box.image})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
            }}
            className="ImageDiv"
          ></div>
          <div className="Text-Essencia">
            <h3>{Box.Titulo}</h3>
            <p>{parse(Box.texto)}</p>
          </div>
        </div>
      ))}
    </div>
  );
};
